import React from "react"
import Box from "@material-ui/core/Box"
import RoomIcon from '@material-ui/icons/Room';
import Container from "@material-ui/core/Container"
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Seo from "../components/Seo"
import Layout from "../components/layout"

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(1)
  },
  social: {
    color: theme.palette.common.black
  },
  container: {
    display: "flex",
    minHeight: "calc(100vh - 460px)",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      minHeight: "calc(100vh - 647px)",
    }
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
    }
  }
}))


const ContactPage = ({ 
  data: { 
    datoCmsContactPage: { 
      seo, 
      seoMetaTags 
    }, 
    datoCmsDetail, 
    datoCmsSocialLink: { 
      facebook, 
      instagram 
    } 
  } 
}) => {
  const classes = useStyles()
  return (
    <Layout logo="black">
      <Seo
        meta={seoMetaTags.tags}
        title={seo.title}
        description={seo.description}
      />
      <Container maxWidth="lg" className={classes.container}>
        <Box mt={10} display="flex" justifyContent="center" width="100%">
          <Box display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <PhoneIcon fontSize="small" className={classes.icon} />
              <Typography>
                <a
                  href={`tel:${datoCmsDetail.phoneNumber}`}
                  rel="noopener noreferrer"
                  size="small"
                  target="_blank"
                  className={classes.link}
                >
                  {datoCmsDetail.phoneNumber}
                </a>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <MailOutlineIcon fontSize="small" className={classes.icon} />
              <Typography>
                <a
                  href={`mailto:${datoCmsDetail.emailAddress}`}
                  rel="noopener noreferrer"
                  size="small"
                  target="_blank"
                  className={classes.link}
                >
                  {datoCmsDetail.emailAddress}
                </a>
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <RoomIcon fontSize="small" className={classes.icon} />
              <Typography>{datoCmsDetail.address}</Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <IconButton
                size="small"
                href={facebook}
                rel="noopener noreferrer"
                target="_blank"
                className={classes.social}
              >
                <FacebookIcon fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                href={instagram}
                rel="noopener noreferrer"
                target="_blank"
                className={classes.social}
              >
                <InstagramIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsContactPage {
      seoMetaTags {
        tags
      }
      seo {
        title
        description
      }
    }
    datoCmsDetail {
      emailAddress
      phoneNumber
      address
    }
    datoCmsSocialLink { 
      facebook
      instagram 
    }
  }
`

export default ContactPage
